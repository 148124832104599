<template>
  <loading v-if="is_loading" />
  <div class="list-all-courses" v-else>
    <AcademicCourse :academicCourses="academicCourses" />
    <SummerCourse :summerCourses="summerCourses" />
    <div class="py-3">
      <b-pagination
        size="lg"
        limit="4"
        align="center"
        :total-rows="rows"
        v-model="currentPage"
      />
    </div>
    <overlay-component :isLoading="overlayLoading" />
  </div>
</template>

<script>
import loading from "@/components/loading/loading.vue";
import { BButton, BCol, BRow, BPagination } from "bootstrap-vue";
import OverlayComponent from "@/components/shared/OverlayComponent";
import SummerCourse from "@/components/classrooms/enrolledCourse/SummerCourse/index.vue";
import AcademicCourse from "@/components/classrooms/enrolledCourse/AcademicCourse/index.vue";
import ExploreCourseCardComponent from "@/components/Explore/CourseCard/ExploreCourseCardComponent.vue";

export default {
  components: {
    BCol,
    BRow,
    BButton,
    loading,
    SummerCourse,
    AcademicCourse,
    BPagination,
    OverlayComponent,
    ExploreCourseCardComponent,
  },
  data() {
    return {
      summerCourses: [],
      academicCourses: [],
      is_loading: true,
      overlayLoading: false,
      rows: null,
      currentPage: 1,
    };
  },
  beforeMount() {
    this.getAcademicCourses();
    this.getSummerCourses();
    const courseId = window.sessionStorage.getItem("ci");
    if (courseId) {
      this.addToCart(courseId);
    }
  },
  methods: {
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },
    getAcademicCourses() {
      this.is_loading = true;
      this.$http
        .get("/student/edu-years")
        .then((response) => {
          this.academicCourses = response.data.data;
          this.is_loading = false;
        })
        .catch((error) => {
          this.$helpers.handleError(error);
          this.is_loading = false;
        });
    },
    getSummerCourses(innerLoading = false) {
      innerLoading ? (this.overlayLoading = true) : (this.is_loading = true);
      this.$http
        .get(`/student/courses?is_summer=true&page=${this.currentPage}`, {
          headers: {
            "X-Page-Size": 20,
          },
        })
        .then((response) => {
          this.summerCourses = response.data.data;
          this.rows = response.data.meta.total;
          innerLoading
            ? (this.overlayLoading = false)
            : (this.is_loading = false);
        })
        .catch((error) => {
          innerLoading
            ? (this.overlayLoading = false)
            : (this.is_loading = false);
          this.$helpers.handleError(error);
        });
    },
    async addToCart(courseId) {
      try {
        this.is_loading = true;
        const formData = new FormData();
        formData.append("course_id", courseId);
        formData.append("qty", 1);
        await this.$http.post("/student/cart", formData);
        this.$helpers.makeToast(
          "success",
          this.$t("success"),
          this.$t("g.course_added_to_cart_successfully")
        );
        sessionStorage.removeItem("ci");
        this.$router.push("/cart");
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.is_loading = false;
      }
    },
  },
  watch: {
    currentPage(newValue) {
      newValue && this.getSummerCourses(true);
    },
  },
};
</script>

<style lang="scss"></style>
