<template>
  <div class="list-academic-courses">
    <b-row class="academic-courses-header">
      <feather-icon
        :icon="isCollapsed ? 'ChevronUpIcon' : 'ChevronDownIcon'"
        size="18"
        class="mr-1 cursor-pointer"
        @click="toggleCollapse"
      />
      <p>{{ $t("g.classrooms/allCourses/academic-courses") }}</p>
      <hr class="border-2" />
    </b-row>
    <b-collapse id="academic-courses" visible>
      <b-row class="academic-courses-body m-0">
        <b-card
          class="course-card cursor-pointer"
          v-for="course in academicCourses"
          :key="course.id"
          :title="course.name"
          :img-src="
            course.media && course.media.length
              ? course.media[0].path
              : $helpers.uiAvatar(course.name)
          "
          :img-alt="course.name"
          img-top
          @click="
            $router.push({ name: 'academicCourses', params: { id: course.id } })
          "
        >
          <b-card-text>{{
            `${course.courses_count} ${$t("g.classrooms/allCourses/courses")}`
          }}</b-card-text>
        </b-card>
      </b-row>
    </b-collapse>
  </div>
</template>

<script>
import { BButton, BCard, BCardText, BCollapse, BRow } from "bootstrap-vue";

export default {
  name: "AcademicCourse",
  components: {
    BRow,
    BCard,
    BButton,
    BCollapse,
    BCardText,
  },
  props: {
    academicCourses: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isCollapsed: false,
    };
  },
  methods: {
    toggleCollapse() {
      this.$root.$emit("bv::toggle::collapse", "academic-courses");
      this.isCollapsed = !this.isCollapsed;
    },
  },
};
</script>

<style lang="scss" src="./_index.scss" />
